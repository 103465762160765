import Divider from '../../Divider';
import NumField from '../../NumField';
import { CapsuleTabContentBody, CapsuleTabContentHeader, ContentRow } from '../CapsuleComponents';
import { addDays, format } from 'date-fns/fp';
import { IFormState } from '../../../semshared/utils/lister';
import ServiceTypeSelection, { HugBase } from '../../ServiceTypeSelection';
import Icon from '../../Icon';
import DaySwitch from '../../DaySwitch';
import { LinkButton } from '../../LinkButton';
import { useEffect, useState } from 'react';
import { EPricing_ProductLookupCode } from '../../../semshared/pricelist/quickprice';

interface ServiceTypeSectionProps {
  formState: IFormState
  setFormState: (formState: IFormState) => void;
  meetingRoomsDay: any;
  setMeetingRoomsDay: any;
  advancedMode: boolean;
  setDrawerShow: (s: string) => void;
  setPopupShow: (s: string | null) => void;
}

const ServiceTypeSection = ({ formState, setFormState, advancedMode, meetingRoomsDay, setMeetingRoomsDay, setDrawerShow, setPopupShow }: ServiceTypeSectionProps) => {

  const [currentDay, setCurrentDay] = useState(formState.days.find(day => day.day === meetingRoomsDay))

  useEffect(() => {
    setCurrentDay(formState.days.find(day => day.day === meetingRoomsDay))
  }, [meetingRoomsDay, formState])

  const _guestsValue = (type: string) => {
    //console.log(type, formState.days.filter(day => day.day === meetingRoomsDay)[0].serviceTypes)
    return currentDay?.serviceTypes.filter(st => st.type === type).length === 1 ? currentDay?.serviceTypes.filter(st => st.type === type)[0].guestsCount : 0;
  }

  const _renderContent = (type: 'SMALL' | 'MEDIUM' | 'REGULAR') => {
      return <><div style={{ display: 'flex', alignItems:'center', flexDirection: advancedMode ? 'row' : 'column', justifyContent: 'space-between', margin: '10px 5px', gap: 10 }}>
        <div style={advancedMode ? {} : { marginBottom: 20}}>
          {type === 'SMALL' && <Icon name="serviceTypeSmall" />}
          {type === 'MEDIUM' && <Icon name="serviceTypeMedium" />}
          {type === 'REGULAR' && <Icon name="serviceTypeRegular" />}
          </div>
        <div style={{ display: 'flex', alignItems:'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ width: '100%', textAlign: 'left', fontSize: '14px' }}>
        {type === 'SMALL' && <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <div style={{ marginBottom: 5 }}>Pauschale "klein"</div>
          <div>✓ 1x Kaffeepause</div>
        </div>}
        {type === 'MEDIUM' && <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <div style={{ marginBottom: 5 }}>Pauschale "Halbtags"</div>
          <div>✓ 1x Kaffeepause</div>
          <div>✓ 1x Mittagessen</div>
        </div>}
        {type === 'REGULAR' && <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <div style={{ marginBottom: 5 }}>Pauschale "Ganztags"</div>
          <div>✓ 2x Kaffeepause</div>
          <div>✓ 1x Mittagessen</div>
        </div>}
        </div>
       {advancedMode && <NumField value={_guestsValue(type)} onChange={value => {
        console.log('value: ' + value, type, _guestsValue(type))
        if (value === 0) {
          setFormState({ ...formState, days: formState.days.map(day => ({ 
            ...day, serviceTypes: day.day === meetingRoomsDay ? day.serviceTypes.filter(st => st.type !== type) : day.serviceTypes
          })) })
        }
        if (value > 0) {
          if (currentDay?.serviceTypes.filter(st => st.type === type).length === 1) {
            setFormState({ ...formState, days: formState.days.map(day => day.day === meetingRoomsDay ? ({ ...day, serviceTypes: day.serviceTypes.map(st => st.type === type ? { ...st, guestsCount: value } : st) }) : ({ ...day })) })
          } else {
            setFormState({ ...formState, days: formState.days.map(day => day.day === meetingRoomsDay ? ({ ...day, serviceTypes: [...day.serviceTypes, { type, guestsCount: value }] }) : ({ ...day })) })
          }
        }
          /*if (currentDay?.serviceTypes.filter(st => st.type === type).length === 1) {
            setFormState({ ...formState, days: formState.days.map(day => ({ 
              ...day, serviceTypes: day.day === meetingRoomsDay ? day.serviceTypes.filter(st => st.type !== type) : day.serviceTypes
            })) })
          } else {
            setFormState({ ...formState, days: formState.days.map(day => day.day === meetingRoomsDay ? ({ ...day, serviceTypes: day.serviceTypes.map(st => st.type === type ? { ...st, guestsCount: value } : st) }) : ({ ...day })) })
          }*/
        }} />}
        </div>
        
        </div>
        </>
    }

  const _renderType = (type: any) => {
    return <ServiceTypeSelection fullWidth={advancedMode} selected={formState.days[0].serviceTypes.filter(st => st.type === type).length === 1} onClick={() => {
      if (formState.days[0].serviceTypes.filter(st => st.type === type).length === 1) {
        setFormState({ ...formState, days: formState.days.map(day => ({ 
          ...day, serviceTypes: day.serviceTypes.filter(st => st.type !== type)
        })) })
      } else {
        setFormState({ ...formState, days: formState.days.map(day => ({ ...day, serviceTypes: [{ type, guestsCount: day.totalGuests }] })) })
      }
    }}>{_renderContent(type)} </ServiceTypeSelection>
  }

  const _renderTypeAdvanced = (type: any) => {
    return <HugBase selected={false} fullWidth>{_renderContent(type)} </HugBase>
  }


  const _renderAddonComponent = (lookupCode: 'ADDON_COFFEE' | 'ADDON_DINNER', label: string) => {
   return <><button style={{
    color: formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode).length > 0 ? '#1AB683' : '#AC2A6E',
    border: formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode).length > 0 ? '#1AB683 2px solid' : '#AC2A6E 2px solid',
    borderRadius: 20,
    background: 'transparent',
    fontWeight: 'bold',
    padding: '10px',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer'
   }} onClick={() => {
      if (formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode).length === 0) {
        setFormState({ ...formState, days: formState.days.map(day => day.day === meetingRoomsDay ? ({ ...day, addons: [ ...day.addons, { lookupCode, count: 1 }] }) : day) })
      } 
      if (formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode).length > 0) {
        setFormState({ ...formState, days: formState.days.map(day => day.day === meetingRoomsDay ? ({ ...day, addons: day.addons.filter(a => a.lookupCode !== lookupCode) }) : day) })
      }
    }}>{formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode).length === 0 ? '+' : ''} {label} {formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode).length > 0 ? 'hinzugefügt' : ''}</button>
    {formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode).length > 0 &&  <div style={{
      background: 'white',
      borderRadius: 10,
      padding: 20,
      display: 'flex',
      fontSize: 15,
      lineHeight: 1.5,
    }}>
      <div>Bitte tragen Sie die Anzahl der Personen für <strong>{label}</strong> ein.</div>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        <NumField value={formState.days[meetingRoomsDay].addons.filter(a => a.lookupCode === lookupCode)[0].count} onChange={value => {
          if (value === 0) {
            setFormState({ ...formState, days: formState.days.map(day => day.day === meetingRoomsDay ? ({ ...day, addons: day.addons.filter(a => a.lookupCode !== lookupCode) }) : day) })
          } else if (value > 0) {
            setFormState({ ...formState, days: formState.days.map(day => day.day === meetingRoomsDay ? ({ ...day, addons: day.addons.map(a => a.lookupCode === lookupCode ? { ...a, count: value } : a) }) : day) })
          }
        }} />
      </div>
    </div>
    </div>}</>
  }

  const simple = () => {
    return <>
    <CapsuleTabContentBody>
      <div style={{
        display: 'flex',
        fontFamily: "'Roboto', sans-serif",
        justifyContent: 'space-between',
        gap: 5,
        marginTop: 10,
      }}>
      {[ 'SMALL', 'MEDIUM', 'REGULAR' ].map(t => _renderType(t))}
      </div>
      <div style={{
        marginTop: 20,
        marginBottom: 10,
      }}>In allen Pauschalen ist die Raumnutzung sowie die Standardtechnik enthalten.</div>
      <Divider />
      <LinkButton onClick={() => {
        setDrawerShow('servicetypes')
        setPopupShow(null)
      }}>⇾ Details</LinkButton>
    </CapsuleTabContentBody>
  </>
  }

  const advanced = () => {
    return <>
    <CapsuleTabContentHeader>
      <ContentRow>
        <DaySwitch
          value={meetingRoomsDay}
          max={formState.days.length - 1}
          onChange={v => setMeetingRoomsDay(v)}
          date={addDays(meetingRoomsDay, formState.start)}
        />
      </ContentRow>
    </CapsuleTabContentHeader>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontFamily: "'Roboto', sans-serif",
        justifyContent: 'space-between',
        gap: 5
      }}>
      {[ 'SMALL', 'MEDIUM', 'REGULAR' ].map(t => advancedMode ? _renderTypeAdvanced(t) : _renderType(t))}
      <div style={{ textAlign: 'center', marginTop: 20 }}>
      {advancedMode && (currentDay?.serviceTypes.reduce((acc, st) => acc + st.guestsCount, 0) || 0) <= (currentDay?.totalGuests || 0) && <div style={{ fontSize: 14, fontWeight: 'bold' }}>{currentDay?.serviceTypes.reduce((acc, st) => acc + st.guestsCount, 0)} Personen von {currentDay?.totalGuests} zugeteilt</div>}
      {advancedMode && (currentDay?.serviceTypes.reduce((acc, st) => acc + st.guestsCount, 0) || 0) > (currentDay?.totalGuests || 0) && <div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>{currentDay?.serviceTypes.reduce((acc, st) => acc + st.guestsCount, 0)} Personen von {currentDay?.totalGuests} zugeteilt</div>}
      </div>
      {_renderAddonComponent('ADDON_COFFEE', 'Empfangskaffee')}
      {_renderAddonComponent('ADDON_DINNER', 'Abendessen')}
      </div>
  </>
  }

  return <>
  {advancedMode ? advanced() : simple()}
    </>
}

export default ServiceTypeSection

import React from 'react';
import styled from 'styled-components';

const icons: any = {
  meeting_room: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29_7672)">
        <rect width="24" height="24" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2V12H17V2H7ZM5 1V12C4.44772 12 4 12.4477 4 13V16C4 16.5523 4.44772 17 5 17H8.51816L5.14836 22.4759C4.85891 22.9463 5.00556 23.5622 5.47592 23.8517C5.94628 24.1411 6.56223 23.9945 6.85168 23.5241L10.8517 17.0241C10.8566 17.0161 10.8614 17.0081 10.8661 17H11V21.125C11 21.6082 11.4477 22 12 22C12.5523 22 13 21.6082 13 21.125V17H13.134C13.1386 17.0081 13.1434 17.0161 13.1484 17.0241L17.1484 23.5241C17.4378 23.9945 18.0538 24.1411 18.5241 23.8517C18.9945 23.5622 19.1411 22.9463 18.8517 22.4759L15.4819 17H19C19.5523 17 20 16.5523 20 16V13C20 12.4477 19.5523 12 19 12V1C19 0.447715 18.5523 0 18 0H6C5.44772 0 5 0.447716 5 1ZM18 15V14H6L6 15H12H18ZM8 5C8 4.44772 8.44772 4 9 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H9C8.44772 6 8 5.55228 8 5ZM9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H12C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7H9Z"
          fill="#082B42"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_7672">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  ),
  people: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7C12 8.65685 10.6569 10 9 10C7.34315 10 6 8.65685 6 7ZM9 2C6.23858 2 4 4.23858 4 7C4 9.76142 6.23858 12 9 12C11.7614 12 14 9.76142 14 7C14 4.23858 11.7614 2 9 2ZM16 2C15.4477 2 15 2.44772 15 3C15 3.55228 15.4477 4 16 4C17.6569 4 19 5.34315 19 7C19 8.65685 17.6569 10 16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12C18.7614 12 21 9.76142 21 7C21 4.23858 18.7614 2 16 2ZM2.60761 17.0902C2.13157 17.6428 2 18.3604 2 19V22.5C2 23.0523 1.55228 23.5 1 23.5C0.447715 23.5 0 23.0523 0 22.5V19C0 18.1396 0.168434 16.8572 1.09239 15.7848C2.04011 14.6848 3.61095 14 6 14H13C14.0039 14 15.2248 14.5337 16.1721 15.3375C17.1427 16.1611 18 17.4172 18 19V23C18 23.5523 17.5523 24 17 24C16.4477 24 16 23.5523 16 23V19C16 18.1828 15.5574 17.4389 14.8781 16.8625C14.1754 16.2663 13.3964 16 13 16H6C3.98905 16 3.05989 16.5652 2.60761 17.0902ZM19 14C18.4477 14 18 14.4477 18 15C18 15.5523 18.4477 16 19 16C20.5138 16 22 17.2161 22 19V23C22 23.5523 22.4477 24 23 24C23.5523 24 24 23.5523 24 23V19C24 15.9839 21.4862 14 19 14Z"
        fill="#082B42"
      />
    </svg>
  ),
  coffee: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path d="M10.5 9H2C1.44772 9 1 9.44772 1 10V12C1 14.6667 2.9 20 10.5 20" stroke="#082B42" strokeWidth="2" />
      <path d="M10 9H18.5C19.0523 9 19.5 9.44772 19.5 10V12C19.5 14.6667 17.6 20 10 20" stroke="#082B42" strokeWidth="2" />
      <path d="M20 11C20.8333 11.1667 23 12 23 14C23 16 20 17 18.5 16.5" stroke="#082B42" strokeWidth="2" />
      <path d="M6.5 2V2C5.61111 3.18519 5.61111 4.81481 6.5 6V6" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M11 2V2C10.1111 3.18519 10.1111 4.81481 11 6V6" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 2V2C15.1111 3.18519 15.1111 4.81481 16 6V6" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M2 23H18.5" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  doublebed: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_29_7698)">
        <rect width="24" height="24" />
        <rect x="3" y="1" width="7" height="5" rx="1" stroke="#082B42" strokeWidth="2" />
        <rect x="14" y="1" width="7" height="5" rx="1" stroke="#082B42" strokeWidth="2" />
        <path
          d="M1 22V13.8028L3.2766 17.2177C4.05753 18.3891 5.54797 18.8599 6.86009 18.3496L23 12.073V22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22ZM23 9.13278V11L22.6376 10.068L6.1352 16.4856C5.69782 16.6557 5.20101 16.4987 4.9407 16.1083L2.03727 11.7531L23 9.13278ZM1.2164 11.8557L1 12V11.8828L1.2164 11.8557Z"
          stroke="#082B42"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_7698">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  ),
  calendar: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1C5 0.447715 5.44772 0 6 0H7C7.55228 0 8 0.447715 8 1V3H15V1C15 0.447715 15.4477 0 16 0H17C17.5523 0 18 0.447715 18 1V3H19C20.6569 3 22 4.34315 22 6V21C22 22.6569 20.6569 24 19 24H4C2.34315 24 1 22.6569 1 21V6C1 4.34315 2.34315 3 4 3H5V1ZM19 5H4C3.44772 5 3 5.44772 3 6V21C3 21.5523 3.44772 22 4 22H19C19.5523 22 20 21.5523 20 21V6C20 5.44771 19.5523 5 19 5ZM8 8.5C8 9.32843 7.32843 10 6.5 10C5.67157 10 5 9.32843 5 8.5C5 7.67157 5.67157 7 6.5 7C7.32843 7 8 7.67157 8 8.5ZM6.5 15C7.32843 15 8 14.3284 8 13.5C8 12.6716 7.32843 12 6.5 12C5.67157 12 5 12.6716 5 13.5C5 14.3284 5.67157 15 6.5 15ZM8 18.5C8 19.3284 7.32843 20 6.5 20C5.67157 20 5 19.3284 5 18.5C5 17.6716 5.67157 17 6.5 17C7.32843 17 8 17.6716 8 18.5ZM11.5 10C12.3284 10 13 9.32843 13 8.5C13 7.67157 12.3284 7 11.5 7C10.6716 7 10 7.67157 10 8.5C10 9.32843 10.6716 10 11.5 10ZM13 13.5C13 14.3284 12.3284 15 11.5 15C10.6716 15 10 14.3284 10 13.5C10 12.6716 10.6716 12 11.5 12C12.3284 12 13 12.6716 13 13.5ZM11.5 20C12.3284 20 13 19.3284 13 18.5C13 17.6716 12.3284 17 11.5 17C10.6716 17 10 17.6716 10 18.5C10 19.3284 10.6716 20 11.5 20ZM18 8.5C18 9.32843 17.3284 10 16.5 10C15.6716 10 15 9.32843 15 8.5C15 7.67157 15.6716 7 16.5 7C17.3284 7 18 7.67157 18 8.5ZM16.5 15C17.3284 15 18 14.3284 18 13.5C18 12.6716 17.3284 12 16.5 12C15.6716 12 15 12.6716 15 13.5C15 14.3284 15.6716 15 16.5 15Z"
        fill="#082B42"
      />
    </svg>
  ),
  flipchart: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" />
      <path
        d="M2 2H22C22.5523 2 23 2.44772 23 3V16C23 16.5523 22.5523 17 22 17H2C1.44772 17 1 16.5523 1 16V3C1 2.44772 1.44772 2 2 2Z"
        stroke="#082B42"
        strokeWidth="2"
        stroke-linejoin="round"
      />
      <path d="M2 13C2.4 13 15.5 13 22 13" stroke="#082B42" strokeWidth="2" />
      <path d="M6.5 23H16.5" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
      <path d="M9 17V23" stroke="#082B42" strokeWidth="2" />
      <path d="M14 17V23" stroke="#082B42" strokeWidth="2" />
    </svg>
  ),
  download: (color: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1C6.21164 1 5.93731 1.12448 5.74742 1.3415L2.24742 5.3415C2.08792 5.52379 2 5.75778 2 6V22H10.5C11.0523 22 11.5 21.5523 11.5 21C11.5 20.4477 11.0523 20 10.5 20H4V6.37573L6.95377 3H17.5V6.5C17.5 7.05228 17.9477 7.5 18.5 7.5C19.0523 7.5 19.5 7.05228 19.5 6.5V1H6.5ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H14.5C15.0523 9 15.5 8.55228 15.5 8C15.5 7.44772 15.0523 7 14.5 7H7ZM6 12C6 11.4477 6.44772 11 7 11H12.5C13.0523 11 13.5 11.4477 13.5 12C13.5 12.5523 13.0523 13 12.5 13H7C6.44772 13 6 12.5523 6 12ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H10.5C11.0523 17 11.5 16.5523 11.5 16C11.5 15.4477 11.0523 15 10.5 15H7ZM18.5 11C19.0523 11 19.5 11.4477 19.5 12V20.5568L21.4188 19.1863C21.8682 18.8652 22.4927 18.9693 22.8138 19.4187C23.1348 19.8682 23.0307 20.4927 22.5813 20.8137L19.0813 23.3137C18.7336 23.5621 18.2665 23.5621 17.9188 23.3137L14.4188 20.8137C13.9694 20.4927 13.8653 19.8682 14.1863 19.4187C14.5073 18.9693 15.1318 18.8652 15.5813 19.1863L17.5 20.5568V12C17.5 11.4477 17.9477 11 18.5 11Z"
        fill={color ? color : '#082B42'}
      />
    </svg>
  ),
  chevronup: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
      <path d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  chevrondown: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14" stroke="#082B42" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  chevronleft: (color: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(270deg)' }}>
      <path
        d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14"
        stroke={color ? color : '#082B42'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  chevronright: (color: string) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }}>
      <path
        d="M5 14L11.3492 8.55782C11.7237 8.23683 12.2763 8.23683 12.6508 8.55782L19 14"
        stroke={color ? color : '#082B42'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  checkmark: (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"/></svg>
  ),
  error: (
    <svg height="24" viewBox="0 0 32 32" width="24" xmlns="http://www.w3.org/2000/svg" ><g><g id="Error_1_"><g id="Error"><circle cx="16" cy="16" id="BG" r="16" fill='#D72828'/><path d="M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z" id="Exclamatory_x5F_Sign" fill="#E6E6E6"/></g></g></g></svg>
  ),
  place: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="surface1">
    <path d="M 12 0 C 7.40625 0 3.671875 3.738281 3.671875 8.328125 C 3.671875 12.75 11.226562 23.078125 11.550781 23.515625 L 11.851562 23.925781 C 11.886719 23.972656 11.941406 24 12 24 C 12.058594 24 12.113281 23.972656 12.152344 23.925781 L 12.449219 23.515625 C 12.773438 23.078125 20.328125 12.75 20.328125 8.328125 C 20.328125 3.738281 16.59375 0 12 0 Z M 12 5.34375 C 13.644531 5.34375 14.984375 6.683594 14.984375 8.328125 C 14.984375 9.972656 13.644531 11.3125 12 11.3125 C 10.355469 11.3125 9.015625 9.972656 9.015625 8.328125 C 9.015625 6.683594 10.355469 5.34375 12 5.34375 Z M 12 5.34375 "/>
    </g>
    </svg>
  ),
  search: (color: string) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
<g id="surface1">
<path stroke={color} fill={color} d="M 18.039062 14.878906 L 24 20.925781 L 21 24 L 14.886719 18.035156 C 13.347656 18.996094 11.566406 19.503906 9.75 19.5 C 4.363281 19.5 0 15.125 0 9.75 C 0 4.363281 4.375 0 9.75 0 C 15.136719 0 19.5 4.375 19.5 9.75 C 19.503906 11.5625 18.996094 13.339844 18.039062 14.878906 Z M 2.996094 9.679688 C 2.996094 13.398438 6.015625 16.429688 9.746094 16.429688 C 13.464844 16.429688 16.496094 13.40625 16.496094 9.679688 C 16.496094 5.957031 13.472656 2.929688 9.746094 2.929688 C 6.027344 2.929688 2.996094 5.949219 2.996094 9.679688 Z M 2.996094 9.679688 "/>
</g>
</svg>
  ),
  trash: (color: string) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1283_4262)">
<path d="M1.5 2H14.5" stroke="#5A6D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 1L11 1" stroke="#5A6D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 5V13C2 14.1046 2.89543 15 4 15H12C13.1046 15 14 14.1046 14 13V5M10 5.45455V12.2727M6 5.45455V12.2727" stroke="#5A6D82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1283_4262">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
  ),
  serviceTypeSmall: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 9C5.89543 9 5 9.89543 5 11V13C5 14.5325 5.53641 16.7744 7.13994 18.6499C8.70719 20.4829 11.2203 21.8794 15 21.9926V22C15.0839 22 15.1673 21.9994 15.25 21.9982C15.3327 21.9994 15.4161 22 15.5 22V21.9926C19.2797 21.8794 21.7928 20.4829 23.3601 18.6499C23.3948 18.6092 23.429 18.5684 23.4628 18.5274C24.4806 18.7661 25.7335 18.5612 26.7353 18.0937C27.8278 17.5838 28.9999 16.5669 28.9999 15C28.9999 13.5836 28.2159 12.6086 27.4083 12.0094C26.7549 11.5246 26.0292 11.2366 25.5 11.0919V11C25.5 9.89543 24.6046 9 23.5 9H15.5H15H7ZM25.8896 16.2813C25.478 16.4734 25.0455 16.5788 24.6643 16.6139C25.2299 15.3946 25.4674 14.1777 25.4968 13.2068C25.7324 13.3088 25.9854 13.4441 26.2166 13.6156C26.7007 13.9747 26.9999 14.4163 26.9999 15C26.9999 15.433 26.6721 15.9161 25.8896 16.2813ZM15 11H7V13C7 14.1342 7.41359 15.8923 8.66006 17.3501C9.84582 18.737 11.8609 19.9415 15.25 19.9979C18.6391 19.9415 20.6542 18.737 21.8399 17.3501C23.0864 15.8923 23.5 14.1342 23.5 13V11H15.5H15ZM7 23C6.44772 23 6 23.4477 6 24C6 24.5523 6.44772 25 7 25H23.5C24.0523 25 24.5 24.5523 24.5 24C24.5 23.4477 24.0523 23 23.5 23H7Z" fill="#5A6D82"/>
</svg>
  ),
  serviceTypeMedium: (
    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.01093 11.5377C2.26622 6.0208 6.94554 1.75539 12.4625 2.01068C17.9794 2.26597 22.2448 6.9453 21.9895 12.4622C21.9608 13.0837 21.8761 13.6884 21.7407 14.272C21.6159 14.81 21.9509 15.3473 22.4889 15.4721C23.0269 15.5969 23.5642 15.2619 23.689 14.7239C23.8516 14.0228 23.953 13.2978 23.9874 12.5547C24.2938 5.93436 19.1753 0.319171 12.5549 0.0128207C5.9346 -0.29353 0.319415 4.82496 0.0130648 11.4453C-0.293286 18.0656 4.82521 23.6808 11.4455 23.9872C11.9972 24.0127 12.4652 23.5862 12.4907 23.0345C12.5162 22.4828 12.0897 22.0148 11.538 21.9893C6.02105 21.734 1.75563 17.0547 2.01093 11.5377ZM6.00668 11.7226C6.15985 8.41248 8.96745 5.85323 12.2776 6.00641C15.5878 6.15958 18.147 8.96718 17.9939 12.2773C17.963 12.9438 17.825 13.5779 17.5981 14.1649C17.399 14.6801 17.6552 15.2591 18.1703 15.4582C18.6854 15.6574 19.2645 15.4012 19.4636 14.886C19.7672 14.1005 19.9508 13.2543 19.9917 12.3698C20.1959 7.95624 16.7836 4.21278 12.3701 4.00855C7.95651 3.80431 4.21305 7.21664 4.00882 11.6302C3.82248 15.657 6.64577 19.1245 10.4938 19.8578C11.0364 19.9612 11.56 19.6052 11.6634 19.0627C11.7667 18.5202 11.4108 17.9965 10.8682 17.8932C7.98382 17.3435 5.86699 14.7412 6.00668 11.7226ZM14.0003 31C14.0003 30.4477 14.448 30 15.0003 30H28.7145C29.2668 30 29.7145 30.4477 29.7145 31C29.7145 31.5523 29.2668 32 28.7145 32H15.0003C14.448 32 14.0003 31.5523 14.0003 31ZM15.1431 18C14.5909 18 14.1431 18.4477 14.1431 19C14.1431 21.5267 14.2385 24.054 15.3284 25.9308C16.5059 27.9584 18.6725 29 22.1431 29C25.6138 29 27.7804 27.9584 28.9579 25.9308C30.0478 24.054 30.1431 21.5267 30.1431 19C30.1431 18.4477 29.6954 18 29.1431 18H15.1431ZM17.0579 24.9264C16.3834 23.7649 16.1906 22.1246 16.1515 20H28.1348C28.0957 22.1246 27.9029 23.7649 27.2284 24.9264C26.5392 26.113 25.2058 27 22.1431 27C19.0804 27 17.747 26.113 17.0579 24.9264Z" fill="#5A6D82"/>
</svg>
  ),
  serviceTypeRegular: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47069 2 2 6.45001 2 11.925C2 14.3755 2.89355 16.6171 4.37739 18.3495C4.73666 18.7689 4.68788 19.4002 4.26842 19.7595C3.84897 20.1188 3.21769 20.07 2.85841 19.6505C1.07687 17.5706 0 14.8719 0 11.925C0 5.33257 5.37904 0 12 0C18.2798 0 23.441 4.79599 23.9576 10.9159C23.9857 11.2488 24 11.5853 24 11.925C24 12.4773 23.5523 12.925 23 12.925C22.4477 12.925 22 12.4773 22 11.925C22 11.6416 21.9881 11.3611 21.9647 11.0841C21.5357 6.00242 17.243 2 12 2ZM20.9168 26C20.9306 25.9771 20.9442 25.954 20.9576 25.9308C22.0475 24.054 22.1429 21.5267 22.1429 19C22.1429 18.4477 21.6952 18 21.1429 18H7.14288C6.5906 18 6.14288 18.4477 6.14288 19C6.14288 21.5267 6.23824 24.054 7.32813 25.9308C8.50564 27.9584 10.6723 29 14.1429 29C16.1244 29 17.6808 28.6605 18.8708 27.9917C18.9131 27.9972 18.9562 28 19 28H29.5714C30.1237 28 30.5714 27.5523 30.5714 27C30.5714 26.4477 30.1237 26 29.5714 26H20.9168ZM6 11.6764C6 8.58548 8.64116 6 12 6C15.1829 6 17.7266 8.32637 17.9791 11.1987C17.9929 11.356 18 11.5153 18 11.6764C18 12.2287 18.4477 12.6764 19 12.6764C19.5523 12.6764 20 12.2287 20 11.6764C20 11.4567 19.9904 11.239 19.9714 11.0236L18.9753 11.1111L19.9714 11.0235C19.6222 7.05044 16.1498 4 12 4C7.62685 4 4 7.39276 4 11.6764C4 13.5857 4.72723 15.3287 5.91965 16.6656C6.28727 17.0778 6.9194 17.1139 7.33156 16.7463C7.74372 16.3787 7.77984 15.7465 7.41222 15.3344C6.52667 14.3415 6 13.0655 6 11.6764ZM6 31C6 30.4477 6.44772 30 7 30H20.7143C21.2666 30 21.7143 30.4477 21.7143 31C21.7143 31.5523 21.2666 32 20.7143 32H7C6.44772 32 6 31.5523 6 31ZM9.05764 24.9264C8.38314 23.7649 8.1903 22.1246 8.1512 20H20.1346C20.0955 22.1246 19.9026 23.7649 19.2281 24.9264C18.539 26.1131 17.2056 27 14.1429 27C11.0802 27 9.74679 26.1131 9.05764 24.9264Z" fill="#5A6D82"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 14C15.4477 14 15 14.4477 15 15C15 16.3676 15.0279 17.7353 15.2264 19H17.2542C17.0944 18.1386 17.0293 17.1389 17.0083 16H28.9917C28.9526 18.1246 28.7597 19.7649 28.0852 20.9264C27.3961 22.1131 26.0627 23 23 23C22.1986 23 21.5155 22.9393 20.9315 22.8283C20.8229 23.531 20.6607 24.1799 20.4196 24.7635C21.185 24.9208 22.0429 25 23 25C26.4706 25 28.6372 23.9584 29.8148 21.9308C30.9046 20.054 31 17.5267 31 15C31 14.4477 30.5523 14 30 14H16Z" fill="#5A6D82"/>
</svg>
  )
};

interface IconProps {
  name?: string;
  color?: string;
}

const Icon = ({ name, color }: IconProps) => {
  return <>{name && icons && (typeof icons[name] === 'function' ? icons[name](color) : icons[name])}</>;
};

export default Icon;

import React from 'react';

interface LinkButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const LinkButton = ({ onClick, children }: LinkButtonProps) => {
  
  return <div style={{
    color: '#AC2A6E',
    fontSize: 14,
    fontWeight:'bold',
    cursor: 'pointer',
  }} onClick={() => {
    onClick()
  }}>{children}</div>
}
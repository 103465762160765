import Divider from '../../Divider';
import NumField from '../../NumField';
import { format, addDays } from 'date-fns/fp';
import { CapsuleTabContentBody, CapsuleTabContentHeader, ContentRow } from '../CapsuleComponents';
import { IFormState } from '../../../semshared/utils/lister';
import React from 'react';
import { set } from 'lodash';
import { LinkButton } from '../../LinkButton';

interface GuestSectionProps {
  formState: IFormState;
  setFormState: (formState: IFormState) => void;
  simpleMode: boolean;
  setSimpleMode: (simpleMode: boolean) => void;
  setDrawerShow: (s: string) => void;
  setPopupShow: (s: string | null) => void;
}

const GuestSection = ({ formState, setFormState, simpleMode, setSimpleMode, setDrawerShow, setPopupShow }: GuestSectionProps) => {

  const simple = () => {
    return <ContentRow>
        <div
          style={{
            display: 'flex',
            fontFamily: 'Roboto, sans-serif',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              paddingRight: 0,
              alignSelf: 'center',
            }}
          >
            <NumField
              value={formState.days[0].totalGuests}
              onChange={value => {
                setFormState({
                  ...formState,
                  days: formState.days.map((day, i) => {
                      return {
                        ...day,
                        totalGuests: value,
                      }
                    })
                });
              }}
            />
          </div>

        </div>
        {simpleMode && <>
        <Divider />
        <LinkButton onClick={() => {
                                    setDrawerShow('participiants');
                                    setPopupShow(null);
                                }}>⇾ Details</LinkButton>
      </>}
      </ContentRow>
  }

  const advanced = () => {
    return <ContentRow>
        {formState.start &&
          [...Array(formState.days.length)].map((_, index) => (
            <div key={`particants_tab_${index}`}>
              <div
                style={{
                  display: 'flex',
                  fontFamily: 'Roboto, sans-serif',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    minWidth: 50,
                    paddingRight: 40,
                    textAlign: 'left',
                  }}
                >
                  <>
                    <strong>Tag {index + 1}</strong>
                    <div>{format('dd.MM.yyyy', addDays(index, formState.start || new Date()))}</div>
                  </>
                </div>
                <div
                  style={{
                    paddingRight: 0,
                    alignSelf: 'center',
                  }}
                >
                  <NumField
                    value={formState.days[index].totalGuests}
                    onChange={value => {
                      setFormState({
                        ...formState,
                        days: formState.days.map((day, i) => {
                          if (index === i) {
                            return {
                              ...day,
                              totalGuests: value,
                            };
                          } else {
                            return day;
                          }
                        }),
                      });
                    }}
                  />
                </div>
              </div>
              <Divider />
            </div>
          ))}
      </ContentRow>
  }

  return <>
    {simpleMode && <CapsuleTabContentHeader>Bitte geben Sie die Teilnehmeranzahl an.</CapsuleTabContentHeader>}
    <CapsuleTabContentBody>
      {simple()}
    </CapsuleTabContentBody>
  </>
};
export default GuestSection;

import React from 'react';
import styled from 'styled-components';

export const HugBase = styled.div<{ selected: boolean; fullWidth?: boolean; onClick?: () => void; }>`
  width: ${props => props.fullWidth ? '100%' : '215px'};
padding: 16px 24px 16px 24px;
border-radius: 8px;
border: 2px 0px 0px 0px;

border: 2px solid ${props => props.selected ? '#1AB683' : '#E2E6EB'};
position: relative;
cursor: ${props => props.onClick ? 'pointer' : 'default'};
box-sizing: border-box;
`;

const HugSelectedBase = styled.div`
  background-color: #FFFFFF;
  color: #1AB683;
  position: absolute;
  top: -8px;
  left: calc(50% - 40px);
  font-size: 14px;
`

interface HugProps {
  children: React.ReactNode;
  selected: boolean;
  fullWidth?: boolean;
  onClick: () => void;
}

const Hug = ({ children, selected, onClick, fullWidth }: HugProps) => {
  return (
      <HugBase onClick={onClick} selected={selected} fullWidth={fullWidth}>
        {selected && <HugSelectedBase>
          Ausgewählt  
        </HugSelectedBase>}
        {children}
      </HugBase>
  );
};

export default Hug;

// a input field with time validation
import React from "react";
import { useRef } from "react";
import styled from "styled-components";

const BaseInput = styled.input`
  border: none;
  background-color: #EFF4FA;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-top: -15px;
`;

interface TimeFieldProps {
  value: string;
  onChange: (value: string) => void;
}

const TimeField = ({ value, onChange, ...inputProps }: TimeFieldProps) => {
  const ref = useRef(null);

  return (
    <BaseInput
      type="time"
      value={value}
      onChange={e => onChange(e.target.value)}
      ref={ref}
      {...inputProps}
    />
  );
}

export default TimeField;
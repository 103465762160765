export function ValidateEmail(email: string) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

export function createShadow(root: any) {
  const div = document.createElement('div');
  const style = document.createElement('style');
  style.innerHTML = ':host { all: initial; }';
  const shadow = div.attachShadow({ mode: 'open' });
  shadow.appendChild(style);
  root.appendChild(div);
  return shadow;
}
import DaySwitch from '../../DaySwitch';
import Divider from '../../Divider';
import NumField from '../../NumField';
import Radio from '../../Radio';
import SimpleTable from '../../SimpeTable';
import { IFormState } from '../../../semshared/utils/lister';
import { addDays } from 'date-fns/fp';
import { CapsuleTabContentBody, CapsuleTabContentHeader, ContentRow } from '../CapsuleComponents';
import Dropdown from '../../DropDown';
import AddRemoveButton from '../../AddRemoveButton';
import { AvailabilityType } from '../../../semshared/availability/availability';
import TimeField from '../../TimePicker';
import { LinkButton } from '../../LinkButton';
import Icon from '../../Icon';

interface MeetingRoomsSectionProps {
  formState: IFormState;
  setFormState: any;
  meetingRoomsDay: any;
  setMeetingRoomsDay: any;
  shadowRoot: any;
  availabilityChecker: AvailabilityType | null;
  setDrawerShow: (s: string) => void;
  setPopupShow: (s: string | null) => void;
}

const MeetingRoomsSectionDrawer = ({ formState, setFormState, meetingRoomsDay, setMeetingRoomsDay, shadowRoot, availabilityChecker, setPopupShow }: MeetingRoomsSectionProps) => (
  <>
    <CapsuleTabContentHeader>
      <ContentRow>
        <DaySwitch
          value={meetingRoomsDay}
          max={formState.days.length - 1}
          onChange={v => setMeetingRoomsDay(v)}
          date={addDays(meetingRoomsDay, formState.start)}
        />
      </ContentRow>
    </CapsuleTabContentHeader>
    {formState.days[meetingRoomsDay].occupancy.map((m, index) => (
      <div style={{ backgroundColor: 'white', padding: 15, marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
<strong style={{ display: 'block', whiteSpace: 'nowrap' }}>{'Raum ' + (index + 1)}</strong>
<button style={{
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
}} onClick={() => {
  const newDays = formState.days[meetingRoomsDay];
  newDays.occupancy.splice(index, 1);
  newDays.seating.splice(index, 1);
  setFormState({
    ...formState,
    days: formState.days.map((d, i) => {
      if (i === meetingRoomsDay) {
        return newDays;
      }
      return d;
    }),
  });
}
}><Icon name="trash" /></button>
        </div>
<Dropdown
          popupPosition="top"
          shadowRoot={shadowRoot}
          value={formState.days[meetingRoomsDay].seating[index]}
          onChange={value =>
            setFormState({
              ...formState,
              days: formState.days.map((d, i) => {
                if (i === meetingRoomsDay) {
                  return {
                    ...d,
                    seating: d.seating.map((s, j) => {
                      if (j === index) {
                        return value;
                      }
                      return s;
                    }),
                  };
                }
                return d;
              }),
            })
          }
          options={[
            {
              value: 'UFORM',
              label: 'U-Form',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/U-form.png`} />,
            },
            {
              value: 'THEATER',
              label: 'Theater',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/theater.png`} />,
            },
            {
              value: 'PARLAMENT',
              label: 'Parlament',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/parlament.png`} />,
            },
            {
              value: 'CIRCLE',
              label: 'Kreis',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/kreis.png`} />,
            },
            {
              value: 'BANKETT',
              label: 'Bankett',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/bankett.png`} />,
            },
            {
              value: 'COCKTAIL',
              label: 'Cocktail',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/cocktail.png`} />,
            },
            {
              value: 'BLOCK',
              label: 'Block',
              icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/block.png`} />,
            },
          ]}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, gap: 10 }}>
        <TimeField value={formState.days[meetingRoomsDay].occupancy[index].from} onChange={value => {
          setFormState({
            ...formState,
            days: formState.days.map((d, i) => {
              if (i === meetingRoomsDay) {
                return {
                  ...d,
                  occupancy: d.occupancy.map((s, j) => j === index ? { ...s, from: value } : s)
                };
              }
              return d;
            }),
          });
        }} />
        <TimeField value={formState.days[meetingRoomsDay].occupancy[index].to} onChange={value => {
          setFormState({
            ...formState,
            days: formState.days.map((d, i) => {
              if (i === meetingRoomsDay) {
                return {
                  ...d,
                  occupancy: d.occupancy.map((s, j) => j === index ? { ...s, to: value } : s)
                };
              }
              return d;
            }),
          });
        }} />
        </div>
      </div>
    ))}
    <br />
    <LinkButton onClick={() => {
                                                const newDays = formState.days[meetingRoomsDay];
                                                if (availabilityChecker && newDays.occupancy.length >= availabilityChecker?.getMaxMeetingRooms(addDays(meetingRoomsDay, formState.start))) {
                                                  return;
                                                }
                                                newDays.occupancy.push({
                                                  from: '08:00',
                                                  to: '18:00',
                                                })
                                                newDays.seating.push('UFORM');
                                                setFormState({
                                                  ...formState,
                                                  days: formState.days.map((d, i) => {
                                                    if (i === meetingRoomsDay) {
                                                      return newDays;
                                                    }
                                                    return d;
                                                  }),
                                                });
                                    }}>+ Raum hinzufügen</LinkButton>
  </>
);

export default MeetingRoomsSectionDrawer;

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { add, orderBy, set } from 'lodash';
import CapsuleTab from './CapsuleTab';
import Button from '../Button';
import 'react-calendar/dist/Calendar.css';
import { format, addDays, endOfDay } from 'date-fns/fp';
import Icon from '../Icon';
import Drawer from '../Drawer';
import { differenceInDays } from 'date-fns';
import Spinner from '../Spinner';
import DrawerAccordion from '../DrawerAccordion';
import { ValidateEmail } from '../../helper';

import { QuickPriceCalculatorLister, QuickPriceCalculatorTypeLister } from '../../semshared/pricelist/quickprice_lister';
import { BaseCapsule, CapsuleAd, CapsuleTabContainer, ClickOverlay } from './CapsuleComponents';
import CalendarSection from './sections/CalendarSection';
import GuestSection from './sections/GuestsSection';
import MeetingRoomsSectionPopup from './sections/MeetingRoomsSectionPopup';
import MeetingRoomsSectionDrawer from './sections/MeetingRoomsSectionDrawer';
import RoomsSection from './sections/RoomsSection';
import { position } from 'polished';
import { Availability, AvailabilityType } from '../../semshared/availability/availability';
import { context, trace } from '@opentelemetry/api';

import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { Addon, getFormState, getInitialFormState, IFormState, isInitalFormState, updateFormState } from '../../semshared/utils/lister';
import PackageSection from './sections/ServiceTypeSection';
import ServiceTypeSection from './sections/ServiceTypeSection';

interface CapsuleProps {
  label: string;
  shadowRoot: ShadowRoot | null;
  formState: string | null;
  platformUrl: string;
  hotelView: string | null;
}

type ValuePiece = Date | null;

const Capsule = ({
  label,
  shadowRoot,
  formState: s,
  platformUrl,
  hotelView,
}: CapsuleProps) => {
  const isMobileSize = () => {
    return document.documentElement.clientWidth < 920;
  };

  const isHotelView = hotelView === 'true' || hotelView === '1';

  const [totalPriceNet, setTotalPriceNet] = React.useState<string | null>(null);
  const [totalPriceGross, setTotalPriceGross] = React.useState<string | null>(null);

  const [popupShow, setPopupShow] = React.useState<string | null>(null);
  const [drawerShow, setDrawerShow] = React.useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [reservationStatus, setReservationStatus] = React.useState<string>('READY');
  const [emailVerificationCode, setEmailVerificationCode] = React.useState('');
  const [emailVerificationCodeInvalid, setEmailVerificationCodeInvalid] = React.useState(false);

  const [formState, setFormState] = React.useState<IFormState | null>(null);
  const [isInitFormState, setIsInitFormState] = useState<Boolean>(true);

  const [popupExpanded, setPopupExpanded] = React.useState(false);
  const [serviceTypes, setServiceTypes] = React.useState<any[]>([]);
  const [serviceTypesError, setServiceTypesError] = React.useState<string>('');
  const [quickPriceCalculator, setQuickPriceCalculator] = React.useState<QuickPriceCalculatorTypeLister | null>(null);
  const [availabilityChecker, setAvailabilityChecker] = React.useState<AvailabilityType | null>(null);
  const [meetingRoomsDay, setMeetingRoomsDay] = React.useState(0);
  const [errors, setErrors] = React.useState<any>({});

  const [calValue, onChange] = useState<ValuePiece | [ValuePiece, ValuePiece]>([null, null]);

  const [simpleMode, setSimpleMode] = React.useState(true);
  
  /*useEffect(() => {
    if (prevFormStateRef.current.meetingRooms > formState.meetingRooms) {
      setFormState({
        ...formState,
        days: formState.days.map(day => ({
          ...day,
          occupancy: day.occupancy.slice(0, formState.meetingRooms),
        })),
      });
    } else if (prevFormStateRef.current.meetingRooms < formState.meetingRooms) {
      setFormState({
        ...formState,
        days: formState.days.map(day => ({
          ...day,
          occupancy: [...day.occupancy, ...Array.from({ length: formState.meetingRooms - day.occupancy.length }, (_, i) => 'FULLDAY')],
        })),
      });
    }
  }, [formState.meetingRooms]);*/

  useEffect(() => {
    if (formState) {
      updateFormState(formState);
      isInitalFormState(formState).then((isInit) => {
        setIsInitFormState(isInit);
      });
    }
  }, [formState]);

  const [capsuleWrapperPositionLeft, setCapsuleWrapperPositionLeft] = useState<number | undefined>(undefined);
  const [capsuleWrapperPositionTop, setCapsuleWrapperPositionTop] = useState<number | undefined>(undefined);
  const [capsuleWrapperWidth, setCapsuleWrapperWidth] = useState<number | undefined>(undefined);
  const capsuleWrapperRef = React.useRef<HTMLDivElement>(null);

  const [widgetClickedOnce, setWidgetClickedOnce] = useState(false);

  function handleResize() {
    setCapsuleWrapperPositionLeft(capsuleWrapperRef.current?.getBoundingClientRect().left);
    setCapsuleWrapperPositionTop(
      (capsuleWrapperRef.current?.getBoundingClientRect().top || 0) +
        (capsuleWrapperRef.current?.getBoundingClientRect().height || 0) +
        window.scrollY,
    );
    setCapsuleWrapperWidth(capsuleWrapperRef.current?.getBoundingClientRect().width);
  }

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    setInterval(() => {
      handleResize();
    }, 100);
  }, []);

  useEffect(() => {
    getFormState().then((state) => {
      console.log('Form state loaded2', state);
      setFormState(state);
    });
  }, [])

  const _checkAdvancedMode = () => {
    let b = false;
    formState?.days.forEach((day, i) => {
      if (day.serviceTypes.length > 1) {
        console.log('st', day.serviceTypes)
        b = true;
      }
      if (day.serviceTypes.filter(st => st.guestsCount !== day.totalGuests).length > 0) {
        console.log('st2', day.serviceTypes)
        b = true;
      }
      if (day.overnightGuestsDZ > 0) {
        b = true;
      }
    })
    if ((formState?.prevdayGuestsDZ || 0) > 0) {
      b = true;
    }
    return b;
  }

  useEffect(() => {
    if (formState) {
      onChange([formState.start, formState.end]);
    }
    setSimpleMode(!_checkAdvancedMode());
  }, [formState]);

  if (formState === null) {
    return <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}><Spinner /></div>
  }

  const onCalChanged = (value: ValuePiece | [ValuePiece, ValuePiece]) => {
    if (Array.isArray(value) && value.length === 2) {
      const days = differenceInDays(value[1] || formState.end, value[0] || formState.start);
      let daysArray = [];
      if (days >= formState.days.length) {
        daysArray = [
          ...formState.days,
          ...Array.from({ length: days - formState.days.length + 1 }, (_, i) => ({
            day: i + formState.days.length,
            overnightGuestsEZ: 0,
            overnightGuestsDZ: 0,
            totalGuests: 0,
            occupancy: Array.from({ length: formState.meetingRooms }, (_, i) => {
              return {
                from: '08:00',
                to: '18:00',
              }
            }),
            seating: Array.from({ length: formState.meetingRooms }, (_, i) => 'UFORM'),
            addons: [],
            serviceTypes: formState.days[0].serviceTypes.map(st => ({ ...st, guestsCount: 0 })),
          })),
        ];
      } else {
        daysArray = formState.days.slice(0, days + 1);
      }
      setFormState({
        ...formState,
        start: (Array.isArray(value) && value[0]) || endOfDay(formState.start),
        end: (Array.isArray(value) && value[1]) || endOfDay(formState.start),
        days: daysArray,
      });
      onChange(value);
      if (!isHotelView) {
        setPopupShow('place');
      }
    }
  };

  return (
    <>
      {popupShow === 'termin' && (
        <div
          style={{
            borderTopLeftRadius: '32px',
            borderTopRightRadius: '32px',
            position: 'absolute',
            width: capsuleWrapperWidth,
            backgroundColor: 'white',
            height: (capsuleWrapperRef.current?.getBoundingClientRect().height || 0) + 'px',
          }}
        ></div>
      )}
      <div ref={capsuleWrapperRef} style={{ position: 'relative' }} onClick={() => {
        if (!widgetClickedOnce) {
          setWidgetClickedOnce(true);
        }
      }}>
          <>
            <BaseCapsule
              className="SeminargoCapsule"
              expanded={true}
              slimMode={false}
            >
                <>
                  <CapsuleTabContainer>
                  
                    <CapsuleTab
                      name="termin"
                      label="Termin"
                      icon="calendar"
                      description={
                        (formState.start && formState.end && format('dd.MM.', formState.start) + ' - ' + format('dd.MM.yy', formState.end)) || 'keine'
                      }
                      popupShow={popupShow}
                      expanded={popupExpanded}
                      setPopupShow={setPopupShow}
                      setDrawerShow={setDrawerShow}
                      simpleMode={simpleMode}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={<CalendarSection setPopupShow={setPopupShow} setDrawerShow={setDrawerShow} availabilityChecker={availabilityChecker} quickPriceCalculator={quickPriceCalculator} vertical={false} fullScreen={false} drawer={false} onCalChanged={onCalChanged} calValue={calValue} />}
                    />
                    {!isHotelView && <CapsuleTab
                        name="place"
                        label="Ort"
                        icon="place"
                        description={formState.placeName}
                        setPopupShow={setPopupShow}
                        setDrawerShow={setDrawerShow}
                        simpleMode={simpleMode}
                        popupShow={popupShow}
                        expanded={popupExpanded}
                        capsuleLeftPosition={capsuleWrapperPositionLeft}
                        capsuleTopPosition={capsuleWrapperPositionTop}
                        capsuleWidth={capsuleWrapperWidth}
                        popupContent={<div style={{ padding: 20, width: 200}}>
                        <Autocomplete
                          options={{
                            types: ["(regions)"],
                          }}
                          style={{
                            backgroundColor: 'rgb(249, 250, 251)',
                            border: '1px solid rgb(209, 213, 219)',
                            padding: '0.625rem',
                            borderRadius: '0.5rem',
                            width: '100%',
                            boxSizing: 'border-box',
                          }}
                          apiKey={'AIzaSyAqGm1p5W-J2ghfJ4eSJq4yjpMS3yett7Y'}
                          onPlaceSelected={(place) => {
                            //const urlParams = new URLSearchParams(window.location.search);
                            setFormState({
                              ...formState,
                              placeName: place.formatted_address,
                              placeLat: place.geometry.location.lat(),
                              placeLng: place.geometry.location.lng()
                            })
                            setPopupShow('meeting_rooms')
                            //window.location.href = window.location.href.split('?')[0] + '?lat=' + place.geometry.location.lat() + '&lng=' + place.geometry.location.lng() + '&place=' + place.formatted_address;
                          }}
                        />
                        </div>}
                      />}
                    <CapsuleTab
                      icon="meeting_room"
                      name="meeting_rooms"
                      label="Räume"
                      tooltip="Räume"
                      description={simpleMode ? formState.days.map(day => day.occupancy.length).reduce((a, b) => Math.max(a, b), 0).toString() : ''}
                      popupShow={popupShow}
                      setPopupShow={setPopupShow}
                      setDrawerShow={setDrawerShow}
                      simpleMode={simpleMode}
                      expanded={popupExpanded}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={
                        <MeetingRoomsSectionPopup
                          setSimpleMode={setSimpleMode}
                          simpleMode={true}
                          setFormState={setFormState}
                          formState={formState}
                          meetingRoomsDay={meetingRoomsDay}
                          setMeetingRoomsDay={setMeetingRoomsDay}
                          shadowRoot={shadowRoot}
                          availabilityChecker={availabilityChecker}
                          setDrawerShow={setDrawerShow}
                          setPopupShow={setPopupShow}
                        />
                      }
                    />
                    <CapsuleTab
                      icon="people"
                      name="particiants"
                      label="Teilnehmer"
                      description={simpleMode ?
                        Math.max(...formState.days.map(v => v.totalGuests)).toString() :
                        ''
                      }
                      setPopupShow={setPopupShow}
                      setDrawerShow={setDrawerShow}
                      simpleMode={simpleMode}
                      popupShow={popupShow}
                      expanded={popupExpanded}
                      capsuleLeftPosition={capsuleWrapperPositionLeft}
                      capsuleTopPosition={capsuleWrapperPositionTop}
                      capsuleWidth={capsuleWrapperWidth}
                      popupContent={<GuestSection setPopupShow={setPopupShow} setDrawerShow={setDrawerShow} setSimpleMode={setSimpleMode} simpleMode={simpleMode} setFormState={setFormState} formState={formState} />}
                    />
                      <CapsuleTab
                        name="rooms"
                        label="Zimmer"
                        icon="doublebed"
                        description={simpleMode ? Math.max(
                          ...formState.days.map(v => v.overnightGuestsEZ),
                          formState.prevdayGuestsEZ,
                        ).toString() : ''
                        }
                        setPopupShow={setPopupShow}
                        setDrawerShow={setDrawerShow}
                        simpleMode={simpleMode}
                        popupShow={popupShow}
                        expanded={popupExpanded}
                        capsuleLeftPosition={capsuleWrapperPositionLeft}
                        capsuleTopPosition={capsuleWrapperPositionTop}
                        capsuleWidth={capsuleWrapperWidth}
                        popupContent={<RoomsSection setPopupShow={setPopupShow} setDrawerShow={setDrawerShow} setSimpleMode={setSimpleMode} simpleMode={simpleMode} setFormState={setFormState} formState={formState} />}
                      />

                    <CapsuleTab
                        name="servicetypes"
                        label="Verpflegung"
                        icon="coffee"
                        description={simpleMode ? formState.days[0].serviceTypes.map(st => st.type.replace('REGULAR', 'Ganztags').replace('SMALL', 'Klein').replace('MEDIUM', 'Halbtags')).join(', ') : ''}
                        setPopupShow={setPopupShow}
                        setDrawerShow={setDrawerShow}
                        simpleMode={simpleMode}
                        popupShow={popupShow}
                        expanded={popupExpanded}
                        capsuleLeftPosition={capsuleWrapperPositionLeft}
                        capsuleTopPosition={capsuleWrapperPositionTop}
                        capsuleWidth={capsuleWrapperWidth}
                        dynamicLeftOffset={500}
                        popupContent={<PackageSection setPopupShow={setPopupShow} setDrawerShow={setDrawerShow} meetingRoomsDay={meetingRoomsDay} setMeetingRoomsDay={setMeetingRoomsDay} advancedMode={false} setFormState={setFormState} formState={formState} />}
                      />
                    
                    {!isHotelView && <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '20px',
                      }}
                    >

                    <Button
                       color={'#AC2A6E'}
                       onClick={async () => {
                        setDrawerShow('');
                        setPopupShow(null);
                       }}
                       style={{ marginRight: '0px', border: 0, fontFamily: 'sans-serif', fontSize: 13, fontWeight: 'bold', paddingLeft: 0 }}
                       >
                        Details
                       </Button>

                       {!isInitFormState && <Button
                        color={'#AC2A6E'}
                        onClick={async () => {
                          localStorage.removeItem('formStateManual');
                          getInitialFormState().then((state) => {
                            setFormState(state)
                            var event = new CustomEvent("formStateReset", { });
                            window.dispatchEvent(event);
                          });
                        }}
                        style={{ marginRight: '0px', border: 0, fontFamily: 'sans-serif', fontSize: 15, fontWeight: 'bold', paddingLeft: 0 }}
                      >
                        X
                      </Button>}
                      <Button
                        color={'#AC2A6E'}
                        filled
                        iconPosition="left"
                        onClick={() => {
                          localStorage.setItem('formStateManual', 'true');
                          localStorage.setItem('formState', JSON.stringify(formState));
                          location.href = `${platformUrl}/?lat=${formState.placeLat}&lng=${formState.placeLng}&place=${formState.placeName}&s=${JSON.stringify(formState)}`
                        }}
                      >
                        Suchen
                      </Button>
                    </div>}
                  </CapsuleTabContainer>
                </>
            </BaseCapsule>
            <Drawer
              open={drawerShow !== null}
              onOverlayClick={() => setDrawerShow(null)}
              fullscreen={isMobileSize()}>
                <div
                style={{
                  borderBottom: '1px solid #eef4fc',
                  padding: '10px 20px',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    fontFamily: 'Roboto, sans-serif',
                    marginRight: 10,
                    marginTop: 14,
                  }}
                  onClick={() => setDrawerShow(null)}
                >
                  X
                </div>
                <h1 style={{ fontFamily: 'Roboto, sans-serif', fontSize: 16 }}>Details</h1>
              </div>

              <DrawerAccordion
                value={drawerShow}
                values={[
                  {
                    name: 'termin',
                    label: 'Termin',
                    description:
                      (formState.start && formState.end && format('dd.MM.', formState.start) + ' - ' + format('dd.MM.yy', formState.end)) || 'keine',
                    content: (
                      <>
                        <CalendarSection
                          quickPriceCalculator={quickPriceCalculator}
                          availabilityChecker={availabilityChecker}
                          fullScreen={isMobileSize()}
                          vertical={true}
                          drawer={true}
                          onCalChanged={onCalChanged}
                          calValue={calValue}
                          setDrawerShow={setDrawerShow}
                          setPopupShow={setPopupShow}
                        />
                      </>
                    ),
                  },
                  {
                    name: 'place',
                    label: 'Ort',
                    description: formState.placeName,
                    content: (
                      <div style={{ padding: 20 }}>
                        <Autocomplete
                          options={{
                            types: ["(regions)"],
                          }}
                          style={{
                            backgroundColor: 'rgb(249, 250, 251)',
                            border: '1px solid rgb(209, 213, 219)',
                            padding: '0.625rem',
                            borderRadius: '0.5rem',
                            width: '100%',
                            boxSizing: 'border-box',
                          }}
                          apiKey={'AIzaSyAqGm1p5W-J2ghfJ4eSJq4yjpMS3yett7Y'}
                          onPlaceSelected={(place) => {
                            setFormState({
                              ...formState,
                              placeName: place.formatted_address,
                              placeLat: place.geometry.location.lat(),
                              placeLng: place.geometry.location.lng()
                            })
                          }}
                        />
                      </div>
                    ),
                  },
                  {
                    name: 'meeting_rooms',
                    label: 'Räume',
                    description: formState.meetingRooms.toString(),
                    content: (
                      <MeetingRoomsSectionDrawer
                        setFormState={setFormState}
                        formState={formState}
                        meetingRoomsDay={meetingRoomsDay}
                        setMeetingRoomsDay={setMeetingRoomsDay}
                        shadowRoot={shadowRoot}
                        availabilityChecker={availabilityChecker}
                        setDrawerShow={setDrawerShow}
                        setPopupShow={setPopupShow}
                      />
                    ),
                  },
                  {
                    name: 'participiants',
                    label: 'Teilnehmer',
                    description: simpleMode ?
                      Math.max(...formState.days.map(v => v.totalGuests)).toString() :
                      `${Math.min(...formState.days.map(v => v.totalGuests))} / ${Math.max(...formState.days.map(v => v.totalGuests))}`,
                    content: (
                      <GuestSection
                        setSimpleMode={setSimpleMode}
                        simpleMode={false}
                        setFormState={setFormState}
                        formState={formState}
                        setDrawerShow={setDrawerShow}
                        setPopupShow={setPopupShow}
                      />
                    )
                  },
                  {
                    name: 'rooms',
                    label: 'Zimmer',
                    description: simpleMode ? Math.max(
                      ...formState.days.map(v => v.overnightGuestsEZ),
                      formState.prevdayGuestsEZ,
                    ).toString() :
                      `${Math.min(...formState.days.map(v => v.overnightGuestsEZ), formState.prevdayGuestsEZ)} / ${Math.max(
                        ...formState.days.map(v => v.overnightGuestsEZ),
                        formState.prevdayGuestsEZ,
                      )}` || 'nicht gesetzt',
                    content: (
                      <RoomsSection
                        setSimpleMode={setSimpleMode}
                        setDrawerShow={setDrawerShow}
                        simpleMode={false}
                        setFormState={setFormState}
                        formState={formState}
                        setPopupShow={setPopupShow}
                      />
                    )
                  },
                  {
                    name: 'servicetypes',
                    label: 'Verpflegung',
                    description: simpleMode ? formState.days[0].serviceTypes.map(st => st.type.replace('REGULAR', 'Ganztags').replace('SMALL', 'Klein').replace('MEDIUM', 'Halbtags')).join(', ') : '',
                    content: (
                      <ServiceTypeSection
                        setDrawerShow={setDrawerShow}
                        setFormState={setFormState}
                        meetingRoomsDay={meetingRoomsDay}
                        setMeetingRoomsDay={setMeetingRoomsDay}
                        formState={formState}
                        advancedMode={true}
                        setPopupShow={setPopupShow}
                      />
                    ),
                  },
                ]}
              />
            </Drawer>
            {popupShow !== null && <ClickOverlay open={true} onClick={() => setPopupShow(null)} />}
          </>
      </div>
    </>
  );
};

export default Capsule;

import { useEffect, useState } from 'react';
import styled, { StyleSheetManager, css } from 'styled-components';
import { createShadow } from '../../helper';
import { createPortal } from 'react-dom';

const CapsuleTabContentWrapper = styled.div<{
  leftPosition: number | undefined;
  topPosition: number | undefined;
  expanded: boolean;
  width: string | number | undefined;
  covered: boolean;
  fullScreen: boolean | undefined;
}>`
  background-color: #ffffff;
  height: ${props => (props.fullScreen ? '100%' : 'auto')};
  border-bottom-left-radius: ${props => (props.fullScreen ? '0px' : '32px')};
  border-bottom-right-radius: ${props => (props.fullScreen ? '0px' : '32px')};
  box-shadow: 0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08);
  position: ${props => (props.fullScreen ? 'fixed' : 'absolute')};
  box-sizing: border-box;
  top: ${props => props.topPosition && props.topPosition - 4}px;
  left: ${props => props.leftPosition}px;
  z-index: ${props => (props.covered ? 9999 : 8000)};
  visibility: ${props => (props.expanded ? 'visible' : 'hidden')};
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: ${props => props.width};
  box-: 0px 5px 5px 1px rgb(239, 244, 250);
  overflow: hidden;
  overflow-y: ${props => (props.fullScreen ? 'scroll' : 'hidden')};
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

interface ICapsulePopupProps {
  leftPosition: number | undefined;
  topPosition: number | undefined;
  expanded: boolean;
  width: string | number | undefined;
  covered: boolean;
  fullScreen: boolean | undefined;
  children?: React.ReactNode;
  ref?: React.RefObject<HTMLDivElement>;
}

const CapsulePopup = ({ leftPosition, topPosition, expanded, width, covered, fullScreen, children, ref }: ICapsulePopupProps) => {
  const [shadow, setShadow] = useState<ShadowRoot | null>(null);

  useEffect(() => {
    const shadow = createShadow(document.body);
    setShadow(shadow);
  }, []);

  return (
    <>
      {shadow &&
        createPortal(
          <StyleSheetManager target={shadow}>
            <div style={{ all: 'initial' }}>
              <CapsuleTabContentWrapper
                leftPosition={leftPosition}
                topPosition={topPosition}
                expanded={expanded}
                width={width}
                covered={covered}
                fullScreen={fullScreen}
                ref={ref}
              >
                {children}
              </CapsuleTabContentWrapper>
            </div>
          </StyleSheetManager>,
          shadow,
        )}
    </>
  );
};

export default CapsulePopup;
